const API = {

  usuario: null,
  empresa: null,

  // baseUrl: "http://192.168.0.179:5000/api",
  // baseUrl: "http://192.168.0.179:5010/api",
  // baseUrl: "http://localhost:5000/api",
  baseUrl: "http://configuracoes.serverlondrisoft.com:5010/api",
  //baseUrl: "https://unique-web-api.herokuapp.com/api",

  setAccessToken: function (token) {
    localStorage.setItem("accessToken", token);
  },

  getAccessToken: function () {
    // Alteração para emulação de usuários
    let token = localStorage.getItem("accessToken");
    //let emulado_token = localStorage.getItem("emulado_accessToken");
    //return emulado_token ? emulado_token : token ? token : false;
    return token;
  },

  trocarEmpresa: function (id) {
    this.empresa = this.empresas.filter((obj) => { return obj.id === parseInt(id); })[0];
    localStorage.setItem("accessToken", this.empresa.accessToken);
  },

  call: function (method, path, payload, autoType) {
    let headers = {
      "Accept": "application/json"
    };
    if (!autoType) headers["Content-Type"] = "application/json";

    let token = this.getAccessToken();
    if (token) {
      headers["Authorization"] = "Bearer " + token;
    }

    return fetch(this.baseUrl + path, {
      method: method,
      headers,
      body: !autoType ? JSON.stringify(payload) : payload
    })
      .then(res => {
        return res.json().then(json => ({ json, res }));
      })
      .then(({ json, res }) => {
        if (!res.ok) {
          console.log("--- Ocorreu um erro. Log RES e JSON:");
          console.log(res);
          console.log(json);
          console.log("---");
          return Promise.reject(json);
        }
        return json;
      });
  },

  get: function (path) {
    return this.call("GET", path);
  },

  post: function (path, payload, autoType) {
    return this.call("POST", path, payload, autoType);
  },

  put: function (path, payload) {
    return this.call("PUT", path, payload);
  },

  delete: function (path) {
    return this.call("DELETE", path);
  }
};

export default API;