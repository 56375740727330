import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import API from "../../../../services/api";
import StringUtil from "../../../../services/util/string";

import "./css/mobile.css";
import UI from "../../../../services/interface";

class Menu extends Component {
  navigateClick(pathname) {
    UI.toggleMenu();
    this.props.history.push(pathname);
  }

  logout() {
    localStorage.removeItem("accessToken");
    this.props.history.push({ pathname: "/", state: "LOGOUT" });
  }

  render() {
    let selectEmpresa = {
      fontWeight: "700",
      background: "#4B488B",
      border: "0px",
      borderRadius: "0px",
      color: "#fff"
    }

    return (
      <div
        id="mobile-menu"
        className="mobile-menu"
        style={{display: "none"}}
      >
        <ul>
          <li>
            <span>
              <div className="profile-picture">
                {API.usuario && API.usuario.nome[0]}
              </div>
              &nbsp;
              <span className="nav-profile-name">
                {API.usuario && API.usuario.nome}
              </span>
            </span>
          </li>
          <li className="empresa">
            <select
              name="empresa"
              className="form-control"
              style={selectEmpresa}
              value={API.empresa ? API.empresa.id : 0}
              onChange={(event) => { this.props.history.push({ pathname: "", state: { action: "TROCAR_EMPRESA", value: event.target.value } }); }}
            >
              {API.empresas && API.empresas.map(function (emp) {
                return (
                  <option key={emp.id} value={emp.id}>{StringUtil.mask(emp.cnpj, emp.cnpj.length > 11 ? "99.999.999/9999-99" :  "999.999.999-99")} - {emp.razaoSocial}</option>
                );
              })}
            </select>
          </li>
          <li id="mobile-menu-0">
            <span onClick={() => this.navigateClick("/")}>
              <span className="menu-title">Inicio</span>
            </span>
          </li>
          <li id="mobile-menu-1">
            <span onClick={() => this.navigateClick("/folha/colaboradores")}>
              Colaboradores
            </span>
          </li>
          <li id="mobile-menu-2">
            <span onClick={() => this.navigateClick("/folha/lancamentos")}>
              Lançamento de Eventos
            </span>
          </li>
          <li id="mobile-menu-3">
            <span onClick={() => this.navigateClick("/folha/ferias")}>
              Solicitação de Férias
            </span>
          </li>
          <li id="mobile-menu-4">
            <span onClick={() => this.navigateClick("/folha/rescisao")}>
              Solicitação de Rescisão
            </span>
          </li>
          <li id="mobile-menu-5" >
            <span onClick={() => this.navigateClick("/documentos")}>
              <span>Documentos</span>
            </span>
          </li>
          <li id="mobile-menu-6" >
            <span onClick={this.logout.bind(this)} >
              <span>Logout</span>
            </span>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(Menu);
