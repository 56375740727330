import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import "./css/documento.css";

class DocumentoModal extends Component {
  render() {
    return (
      <div
        className="modal fade"
        id="documento-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
    >
        <div className="modal-dialog modal-doc" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="col-6">
                <h5 className="modal-title" id="nome-documento">arquivo.pdf</h5>
              </div>
              <div className="col-6 text-right">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="modal-body">
              <iframe title="teste" id="iframe-documento" src="/loading" frameBorder="0" className="frame-doc"></iframe>
            </div>
          </div>
        </div>
    </div>
    );
  }
}

export default withRouter(DocumentoModal);
