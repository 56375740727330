import React, { Component } from "react";
import InputMask from "react-input-mask";

import UI from "../../../services/interface";
import API from "../../../services/api";

import "./css/folha.css";
import DataUtil from "../../../services/util/datahora";

import PesquisaColaboradorModal from "./PesquisaColaboradorModal";

class ColaboradorModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addLancamento = this.addLancamento.bind(this);
    this.selecaoColaborador = this.selecaoColaborador.bind(this);
  }

  getInitialState() {
    return {
      pesquisa: "",
      colaboradores: this.props.lista,
      lancamentos: [{
        periodo: DataUtil.hoje().substring(3),
        eventoId: this.props.eventos[0] != null ? this.props.eventos[0].id : "0",
        quantidade: "1",
        valor: "1.00"
      }]
    };
  }

  handleChange(event) {
    let names = event.target.name.split(".");
    if (names.length === 1) {
      this.setState({ [event.target.name]: event.target.value });
    }
    else {
      let name = names[0];
      let index = 0;
      if (name.includes("[")) {
        index = parseInt(name.substring(name.lastIndexOf("[") +1, name.lastIndexOf("]")));
        name = name.substring(0, name.lastIndexOf("["));

        let obj = this.state[name];
        obj[index][names[1]] = event.target.value;
        this.setState({ [name]: obj });
      }
      else {
        let obj = this.state[name];
        obj[names[1]] = event.target.value;
        this.setState({ [name]: obj });
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    UI.loader("show", "Gravando lançamento...");
    UI.toggleModal("#novo-lancamento-modal");

    let selecionados = this.state.colaboradores.filter((obj) => { return obj.selected; });
    if (selecionados.length === 0) {
      UI.alert(false, "É necessário selecionar no mínimo 1 (um) colaborador.");
      UI.loader("hide");
      return;
    }
    for (var i = 0; i < this.state.lancamentos.length; i++) {
      let lancamento = this.state.lancamentos[i];
      if (!DataUtil.validarData("01/" + lancamento.periodo)) {
        UI.alert(false, "O período '" + lancamento.periodo + "' informado é inválido.");
        UI.loader("hide");
        return;
      }
    }

    let total = selecionados.length * this.state.lancamentos.length;
    let count = 0;

    this.state.lancamentos.map((lancamento) => {
      selecionados.map((obj) => {
        let periodo = lancamento.periodo.split("/");

        let data = {
          mes: periodo[0],
          ano: periodo[1],
          colaboradorId: obj.id,
          eventoId: lancamento.eventoId,
          quantidade: lancamento.quantidade,
          valor: lancamento.valor
        };
        
        API.post("/lancamentos", data)
        .then((json) => {
          count++;
          this.props.onSalvar(json.data);        

          if (count === total) {
            UI.loader("hide");
          }
        })
        .catch((json) => {
          UI.alert(false, json.message);
          UI.loader("hide");
        })

        return false;
      });
      return false;
    });
    
    // Zerar selecionados
    this.state.colaboradores.map((colab) => { colab.selected = false; return true; });
    this.setState(this.getInitialState());
  }

  addLancamento() {
    let lancamentos = this.state.lancamentos;
    lancamentos.push({
      periodo: DataUtil.hoje().substring(3),
      eventoId: this.props.eventos[0] != null ? this.props.eventos[0].id : "0",
      quantidade: "1",
      valor: "1.00"
    });
    this.forceUpdate();
  }

  removerLancamento(index) {
    let lancamentos = this.state.lancamentos;
    lancamentos = lancamentos.filter((lanc, i) => {return index !== i;});
    this.setState({ lancamentos: lancamentos });
  }

  pesquisaColaborador() {
    UI.toggleModal("#novo-lancamento-modal");
    UI.toggleModal("#pesquisa-colaborador-modal");
  }

  selecaoColaborador(colaborador) {
    colaborador.selected = !colaborador.selected;
    this.forceUpdate();
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div
          className="modal fade"
          id="novo-lancamento-modal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <i className="fa fa-plus"></i>&nbsp;
                  Novo Lançamento
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              
              <div className="modal-body">
                <div className="form-group row">
                  <div className="col-md-12">
                    <label>Colaboradores (para remover clique sobre)</label>
                    <div className="colaborador-container">
                      { this.state.colaboradores.map((colab) => {
                        if (colab.selected)
                        return (
                          <span key={colab.id} onClick={ () => { this.selecaoColaborador(colab); } } className="colaborador">
                            <i className="fa fa-user"></i>
                            &nbsp;
                            { colab.id + " - " + colab.nome }
                          </span>
                        );
                        return false;
                      })}
                      <span onClick={this.pesquisaColaborador} className="colaborador-add">
                        <i className="fa fa-plus"></i>
                      </span>
                    </div>
                  </div>
                </div>
                
                {/* Múltiplos lançamentos */}
                {this.state.lancamentos.map((lancamento, i) => {
                  return (
                    <div key={i} className="form-group row">
                      <div className="col-md-3">
                        <label>Período</label>
                        <InputMask
                          placeholder={DataUtil.hoje().substring(3)}
                          name={"lancamentos[" + i + "].periodo"}
                          className="form-control"
                          mask="99/9999"
                          onChange={this.handleChange}
                          value={lancamento.periodo}
                        />
                      </div>

                      <div className="col-md-4">
                        <label>Evento</label>
                        <select 
                          name={"lancamentos[" + i + "].eventoId"}
                          className="form-control"
                          onChange={this.handleChange}
                          value={lancamento.eventoId}
                        >
                          { this.props.eventos.map((ev) => {
                            return (
                              <option key={ev.id} value={ev.id}>{ev.nsu + " - " + ev.descricao}</option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="col-md-2">
                        <label>Quantidade</label>
                        <input 
                          name={"lancamentos[" + i + "].quantidade"}
                          type="number" 
                          className="form-control"
                          value={lancamento.quantidade}
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="col-md-2">
                        <label>Valor (R$)</label>
                        <input 
                          name={"lancamentos[" + i + "].valor"}
                          type="number" 
                          className="form-control" 
                          value={lancamento.valor}
                          onChange={this.handleChange}
                        />
                      </div>

                      <div className="col-md-1">
                          <label></label>
                          {i === 0 &&
                          <button onClick={this.addLancamento} type="button" className="btn btn-default btn-action">
                            <i className="fa fa-plus"></i>
                          </button>}
                          {i > 0 &&
                          <button onClick={() => this.removerLancamento(i)} type="button" className="btn btn-default btn-action">
                            <i className="fa fa-times"></i>
                          </button>}
                      </div>
                    </div>
                  );
              })}
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button type="submit" className="btn btn-primary">
                  Pronto
                </button>
              </div>
            </div>
          </div>
        </div>
        
        <PesquisaColaboradorModal lista={this.state.colaboradores} onSelecao={this.selecaoColaborador} />
      </form>
    );
  }
}

export default ColaboradorModal;
