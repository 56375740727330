import { Component } from "react";

import UI from "../../../../services/interface";

class Loading extends Component {

  componentDidMount() {
    UI.loader("show", "Aguarde...");
  }

  render() {
    return false;
  }
}

export default Loading;
