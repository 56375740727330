import React, { Component } from "react";
import UI from "../../../../services/interface";

class Backdrop extends Component {
  state = {};
  render() {
    return (
      <div
        id="mobile-backdrop"
        className="loader-container fadeIn"
        style={{display: "none"}}
        onClick={UI.toggleMenu}
      ></div>
    );
  }
}

export default Backdrop;
