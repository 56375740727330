import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';

import "./css/inicio.css";

import UI from "../../../services/interface";
import API from "../../../services/api";

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      qtd_colaborador: 0,
      qtd_lancamento: 0,
      qtd_ferias: 0,
      qtd_rescisao: 0,
      qtd_documento: 0,
      tot_tamanho: 0
    }
  }

  componentDidMount() {
    UI.loader("show", "Atualizando contadores...");
    API.get("/empresas/resumo")
    .then((json) => {
      // Empresa em branco retorna os demais dados null
      if (json.data.qtd_lancamento !== null) {
        this.setState(json.data);
      }
      UI.loader("hide");
    })
    .catch((json) => {
      UI.alert(false, json.message);
      UI.loader("hide");
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="tile-container">
            <div className="animate-once">
              <Link to="/folha/colaboradores">
                <div className="tile text-center">
                  <i className="fa fa-users"></i>
                  <span>Colaboradores</span>
                  <span className="tile-info">{this.state.qtd_colaborador}</span>
                </div>
              </Link>
            </div>
            <div className="animate-once a05s">
              <Link to="/folha/lancamentos">
                <div className="tile text-center">
                  <img src="/images/icons/money-bag-128.png" alt="" />
                  <span>Eventos</span>
                  <span className="tile-info">{this.state.qtd_lancamento}</span>
                </div>
              </Link>
            </div>
            <div className="animate-once">
              <Link to="/folha/ferias">
                <div className="tile text-center">
                  <img src="/images/icons/beach-128.png" alt="" />
                  <span>Férias</span>
                  <span className="tile-info">{this.state.qtd_ferias}</span>
                </div>
              </Link>
            </div>
            <div className="animate-once a15s">
              <Link to="/folha/rescisao">
                <div className="tile text-center">
                <img src="/images/icons/rescisao-128.png" alt="" />
                  <span>Rescisão</span>
                  <span className="tile-info">{this.state.qtd_rescisao}</span>
                </div>
              </Link>
            </div>
            <div className="animate-once a05s">
              <Link to="/documentos">
                <div className="tile text-center">
                  {/*<span className="tile-info-center">{StringUtil.tamanho(this.state.tot_tamanho)}</span>*/}
                  <i className="fa fa-folder"></i>
                  <span>Documentos</span>
                  <span className="tile-info">{this.state.qtd_documento}</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Inicio);