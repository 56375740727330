import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import InputMask from "react-input-mask";

import DataUtil from "../../../services/util/datahora";
import UI from "../../../services/interface";
import API from "../../../services/api";

class FeriasModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getInitialState = this.getInitialState.bind(this);
  }

  getInitialState() {
    return {
      id: 0,
      colaboradorId: this.props.colaboradores.length > 0 ? this.props.colaboradores[0].id :  0,
      dataInicial: "",
      dataFinal: "",
      diasFerias: 5
    };
  }

  componentWillReceiveProps(props) {
    if (props.solicitacao) {
      let solicitacao = props.solicitacao;
      this.setState({
        id: solicitacao.id,
        colaboradorId: solicitacao.colaborador.id,
        dataInicial: solicitacao.dataInicial,
        dataFinal: solicitacao.dataFinal,
        diasFerias: DataUtil.diferenca(solicitacao.dataInicial, solicitacao.dataFinal)
      });
    }
    else {
      this.setState(this.getInitialState());
    }
  }

  handleChange(event) {
    let names = event.target.name.split(".");
    if (names.length === 1) {
      let state = this.state;
      state[event.target.name] = event.target.value;
      let dataFinal = "";

      if (DataUtil.validarData(this.state.dataInicial)) {
        dataFinal = DataUtil.addDias(this.state.dataInicial, parseInt(this.state.diasFerias));
      }
      this.setState({ [event.target.name]: event.target.value, dataFinal: dataFinal });
    }
    else {
      let obj = this.state[names[0]];
      obj[names[1]] = event.target.value;
      this.setState({ [names[0]]: obj });
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!DataUtil.validarData(this.state.dataInicial) || !DataUtil.validarData(this.state.dataFinal) || this.state.diasFerias < 5) {
      UI.alert(false, "Verifique o Período de Gozo informado.", "info");
      return;
    }
    if (this.state.diasFerias > 30) {
      UI.alert(false, "Dias de Férias acima de 30, verifique.", "info");
      return;
    }

    UI.loader("show", "Salvando solicitação...");
    API.post("/solicitacoes/ferias", this.state)
    .then((json) => {
      this.props.onAddSolicitacao(json.data);
      UI.alert(json.success, json.message);
      UI.loader("hide");
    })
    .catch((json) => {
      UI.alert(false, json.message);
      UI.loader("hide");
    });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div
          className="modal fade"
          id="solicitacao-ferias-modal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <i className="fa fa-plus"></i>&nbsp;
                  Nova Solicitação
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group row">
                  <div className="col-md-12">
                    <label>Colaborador</label>
                    <select
                      name="colaboradorId"
                      className="form-control"
                      value={this.state.colaboradorId}
                      onChange={this.handleChange}
                    >
                      {this.props.colaboradores.map((col) => {
                        return (
                          <option key={col.id} value={col.id}>{col.nsu + " - " + col.nome}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-3">
                    <label>Início do Gozo</label>
                    <InputMask
                      placeholder="99/99/9999"
                      name="dataInicial"
                      className="form-control"
                      mask="99/99/9999"
                      value={this.state.dataInicial}
                      onChange={this.handleChange}
                    />
                  </div>                                  
                  <div className="col-md-2">
                    <label>Dias de Férias</label>
                    <input
                      placeholder="0"
                      name="diasFerias"
                      className="form-control"
                      value={this.state.diasFerias}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Final do Gozo</label>
                    <InputMask
                      readOnly={true}
                      placeholder="99/99/9999"
                      name="dataFinal"
                      className="form-control"
                      mask="99/99/9999"
                      value={this.state.dataFinal}
                      onChange={this.handleChange}
                    />
                  </div>  
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button type="submit" className="btn btn-primary">
                  Pronto
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(FeriasModal);
