import React, { Component } from "react";

import UI from "../../../services/interface";

import "./css/folha.css";

class PesquisaColaboradorModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.handleChange = this.handleChange.bind(this);
  }

  getInitialState() {
    return {
      pesquisa: ""
    };
  }

  handleChange(event) {
    let names = event.target.name.split(".");
    if (names.length === 1) {
      this.setState({ [event.target.name]: event.target.value.toUpperCase() });
    }
    else {
      let obj = this.state[names[0]];
      obj[names[1]] = event.target.value;
      this.setState({ [names[0]]: obj });
    }
  }

  render() {
    let quantidade = 0;
    let colaboradores = this.props.lista.filter((obj) => {
      if (obj.selected) { quantidade++; return true; }
      let matches = obj.nome.toUpperCase().includes(this.state.pesquisa);
      return matches;
    });

    return (
      <div
        className="modal fade"
        id="pesquisa-colaborador-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">          
                  <div className="input-group">
                    <input
                      autoFocus={true}
                      spellCheck={false}
                      placeholder="Pesquisar"
                      name="pesquisa"
                      className="form-control search-control"
                      value={this.state.pesquisa}
                      onChange={this.handleChange}
                    />
                    <div className="input-group-append search">
                      <span className="input-group-text">
                        <i className="fa fa-search fa-lg" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="colaborador-lista">
                { colaboradores.map((colab) => {
                  let classe = colab.selected ? "colaborador-select selecionado" : "colaborador-select";
                  return (
                    <div onClick={() => this.props.onSelecao(colab)} key={colab.id} className={classe}>{ colab.nsu + " - " + colab.nome }</div>
                  );
                }) }
              </div>
            <div className="row">
              <div className="col-md-4">{quantidade} selecionados</div>
              <div className="col-md-8 text-right">
                <button
                  type="button"
                  className="btn btn-light"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button 
                  onClick={() =>  UI.toggleModal("#novo-lancamento-modal")}
                  data-dismiss="modal"
                  type="button"
                  className="btn btn-primary">
                  Pronto
                </button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PesquisaColaboradorModal;
