import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import "./css/documento.css";

import UI from "../../../services/interface";
import API from "../../../services/api";
import StringUtil from "../../../services/util/string";

import DocumentoModal from "./DocumentoModal";

class DocumentoDiretorioModal extends Component {
  constructor(props) {
    super(props);
    this.state = { documentos: [] };
    this.downloadDocumento = this.downloadDocumento.bind(this);
    this.removerDocumento = this.removerDocumento.bind(this);
    this.arquivoChange = this.arquivoChange.bind(this);
    this.getBase64 = this.getBase64.bind(this);
  }

  componentDidUpdate() {
    API.get("/documentos?diretorio=" + encodeURIComponent(this.props.diretorio))
    .then(
    (json) => {
      this.setState({ documentos: json.data });
    })
    .catch(
    (json) => {
      UI.alert(false, json.message);
    })
  }

  arquivoChange(event) {
    if (!this.props.diretorio) {
      UI.alert(false, "Não é permitido enviar arquivos para este diretório.");
      return;
    }

    UI.loader("show", "Enviando arquivo...");
    this.getBase64(event.target.files[0])
  }

  enviarDocumento(doc) {
    API.post("/documentos", doc).then((json) => {
      this.state.documentos.unshift(json.data);
      let documentos = this.state.documentos;
      this.setState({ documentos: documentos });
      
      UI.alert(true, "Documento enviado com sucesso.");
      UI.loader("hide");
    })
      .catch((json) => {
        UI.alert(false, json.message);
        UI.loader("hide");
      })
  }

  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let conteudo = reader.result;
      conteudo = conteudo.substring(conteudo.indexOf("base64,") + 7);

      let doc = {
        arquivoNome: file.name,
        observacoes: file.name,
        diretorio: this.props.diretorio,
        conteudo: conteudo
      }
      this.enviarDocumento(doc)
    };
    reader.onerror = (error) => {
      UI.alert(false, "O arquivo é muito grande para o envio.");
    };
  }

  downloadDocumento(doc) {
    doc.quantidadeDownload++;
    this.forceUpdate();
    UI.abrirDocumento(doc);
  }

  removerDocumento(doc) {
    UI.confirm("Atenção", "Tem certeza que deseja apagar este arquivo?")
      .then((value) => {
        if (value) {
          UI.loader("show", "Excluindo arquivo...");
          API.delete("/documentos/" + doc.id)
            .then((json) => {
              let lista = this.state.documentos.filter((obj, i) => { return obj.id !== doc.id; });
              this.setState({ documentos: lista });
              UI.alert(true, json.message);
              UI.loader("hide");
            })
            .catch((json) => {
              UI.alert(false, json.message);
              UI.loader("hide");
            })
        }
      });
  }

  render() {
    let downloadDocumento = this.downloadDocumento;
    let removerDocumento = this.removerDocumento;

    let documentos = this.state.documentos;
    if (!documentos) return false;

    return (
      <div
        className="modal fade"
        id="documento-diretorio-modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true">
        <div className="modal-dialog modal-doc modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel-2">
                <input id="documento-file" type="file" name="file" className="file-upload-default" style={{ display: "none" }} onChange={this.arquivoChange} />
                <button
                  className="btn btn-primary icon-btn btn-sm btn-anexo"
                  onClick={() => { document.getElementById("documento-file").click(); }}>
                  <i className="fa fa-paperclip fa-2x"></i>
                </button>&nbsp;{this.props.titulo}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i style={{ color: "#fff" }} className="fa fa-remove"></i>&nbsp;
              </button>
            </div>
            <div className="modal-body">
              <div className="doc-container">
                <div className="col-12 table-responsive">
                  <table id="data-table" className="table table-doc">
                    <tbody>
                      {documentos.map(
                        function (doc, i) {
                          if (doc.arquivoNome === "...") return false;
                          let iconClass = "fa " + StringUtil.faFileIcon(doc.arquivoNome) + " fa-2x";
                          return (<tr key={"a_" + i} style={{ cursor: "pointer" }}>
                            <td style={{ width: "1%" }}>&nbsp;<i className={iconClass}></i></td>
                            <td onClick={() => downloadDocumento(doc)}>{doc.observacoes ? doc.observacoes : doc.arquivoNome}</td>
                            <td onClick={() => removerDocumento(doc)} style={{ textAlign: "right", width: "1%" }}><i style={{ color: "#7f0000" }} className="fa fa-remove fa-2x"></i></td>
                          </tr>);
                        }
                      )}
                    </tbody>
                  </table>
                  {(documentos.length === 0) && <div style={{ textAlign: "center", color: "#aaa", padding: "30px" }} className="col-12">Diretório vazio</div>}
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <DocumentoModal url={this.state.documentoUrl} titulo={this.state.documentoNome} />
      </div>
    );
  }
}

export default withRouter(DocumentoDiretorioModal);
