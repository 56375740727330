import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';

import UI from "../../../services/interface";
import API from "../../../services/api";

import StringUtil from "../../../services/util/string";
import DataUtil from "../../../services/util/datahora";

import ColaboradorModal from "./ColaboradorModal";
import DocumentoDiretorioModal from "../documento/DocumentoDiretorioModal";

class ColaboradorLista extends Component {
  constructor(props) {
    super(props);
    this.state = false;
    this.handleChange = this.handleChange.bind(this);
    this.removerColaborador = this.removerColaborador.bind(this);
    this.novoColaborador = this.novoColaborador.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value.toUpperCase() })
  }

  componentDidMount() {
    UI.loader("show", "Buscando colaboradores...")
    API.get("/colaboradores")
    .then((json) => {
      this.setState({ pesquisa: "", colaboradores: json.data });
      UI.loader("hide");
    })
    .catch((json) => {
      UI.alert(false, json.message);
      UI.loader("hide");
    });
  }

  novoColaborador(colab) {
    this.state.colaboradores.unshift(colab);
    this.forceUpdate();
  }

  removerColaborador(colab) {
    UI.confirm("Atenção", "Tem certeza que deseja excluir o colaborador " + colab.nome + "?")
    .then((value) => {
      if (value) {
        UI.loader("show", "Removendo colaborador...");
        API.delete("/colaboradores/" + colab.id)
          .then((json) => {
            let lista = this.state.colaboradores.filter((obj, i) => { return obj.id !== colab.id; });
            this.setState({ colaboradores: lista });
            UI.loader("hide");
            UI.alert(json.success, json.message);
          })
          .catch((json) => {
            UI.loader("hide");
            UI.alert(false, json.message);
          })
      }
    })
  }

  componentWillUpdate() {
    UI.destroyDataTable(this.tableId);
  }

  componentDidUpdate() {
    UI.dataTable(this.tableId);
  }

  abrirDocumentos(colab) {
    this.setState({ diretorio: "\\_COLABORADOR\\" + colab.id, titulo: colab.nome }, () => UI.showModal("#documento-diretorio-modal"));
  }

  render() {
    if (!this.state) return false;

    let colaboradores = this.state.colaboradores.filter((colab) => {
      let matches = false;
      matches = matches || colab.documentacao.numeroCpf.includes(this.state.pesquisa);
      matches = matches || colab.dataNascimento.includes(this.state.pesquisa);
      matches = matches || colab.nome.toUpperCase().includes(this.state.pesquisa.toUpperCase());
      return matches;
    });

    this.tableId = StringUtil.uuid(5);

    return (
      <div className="row">
        <div className="col-md-12">          
          <div className="input-group">
            <input
              autoComplete="off"
              autoFocus={true}
              spellCheck={false}
              placeholder="Pesquisar"
              name="pesquisa"
              className="form-control search-control"
              value={this.state.pesquisa}
              onChange={this.handleChange}
            />
            <div className="input-group-append search">
              <span className="input-group-text">
                <i className="fa fa-search fa-lg" />
              </span>
            </div>
          </div>
        </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div className="form-group row">
                <div className="col-md-6">
                  <h4 className="card-title">COLABORADORES</h4>
                </div>
                <div className="col-md-6 text-right">
                  <button
                    onClick={() => UI.toggleModal("#novo-colaborador-modal")}
                    className="btn btn-add btn-primary"
                    type="button"
                  >
                    <i className="fa fa-plus"></i>&nbsp;
                    Novo Colaborador
                    </button>
                </div>
              </div>

              {colaboradores.length > 0 &&
                <table key={StringUtil.uuid(5)} className="table" id={this.tableId}>
                  <thead>
                    <tr>
                      <th style={{ width: "1%", whiteSpace: "nowrap" }}></th>
                      <th style={{ width: "1%", whiteSpace: "nowrap" }}>Data Admissão</th>
                      <th style={{ width: "1%", whiteSpace: "nowrap" }}>CPF</th>
                      <th style={{ width: "1%", whiteSpace: "nowrap" }}>Data Nascimento</th>
                      <th>Nome Completo</th>
                      <th style={{ width: "1%" }}></th>
                      <th style={{ width: "1%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {colaboradores.map((colab) => {
                      return (
                        <tr key={colab.id}> 
                          <td style={{ whiteSpace: "nowrap" }}><img className="square" alt="Avatar" src={"/images/avatar/" + (colab.sexo === "F" ? "female.png" : "male.png")}></img></td>
                          <td style={{ whiteSpace: "nowrap" }}><span hidden>{DataUtil.ymd(colab.contrato.dataAdmissao)}</span>{colab.contrato.dataAdmissao}</td>
                          <td style={{ whiteSpace: "nowrap" }}>{StringUtil.mask(colab.documentacao.numeroCpf, "999.999.999-99")}</td>
                          <td style={{ whiteSpace: "nowrap" }}>{colab.dataNascimento}</td>
                          <td>{colab.nome}</td>
                          <td style={{ fontWeight: "700", color: "#aaa", whiteSpace: "nowrap" }}>{colab.nsu ? "" : "PENDENTE"}</td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            <button type="button" className="btn btn-default btn-option icon-btn" id="dropdown1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="fa fa-gear"></i>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdown1">
                              <Link to={"/folha/colaboradores/" + colab.id} className="dropdown-item"><i className="fa fa-edit"></i>&nbsp;Editar</Link>
                              <span onClick={() => this.abrirDocumentos(colab)} className="dropdown-item"><i className="fa fa-paperclip"></i>&nbsp;Documentos</span>
                              <span onClick={() => this.removerColaborador(colab)} className="dropdown-item"><i className="fa fa-times"></i>&nbsp;Excluir</span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>}

            </div>
          </div>
        </div>

        <ColaboradorModal onNovoColaborador={this.novoColaborador} />
        <DocumentoDiretorioModal diretorio={this.state.diretorio} titulo={this.state.titulo} />
      </div>
    );
  }
}

export default withRouter(ColaboradorLista);
