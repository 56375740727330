import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import API from "../../../services/api";
import UI from "../../../services/interface";

import StringUtil from "../../../services/util/string";

import LancamentoModal from "./LancamentoModal";

class LancamentoLista extends Component {
  constructor(props) {
    super(props);
    this.state = false;
    this.handleChange = this.handleChange.bind(this);
    this.salvarLancamento = this.salvarLancamento.bind(this);
    this.removerLancamento = this.removerLancamento.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value.toUpperCase() })
  }

  componentDidMount() { 
    // 1 - Carregar Lançamentos
    UI.loader("show", "Carregando lancamentos...");
    API.get("/lancamentos")
    .then((json) => {
      let lancamentos = json.data;

      // 2 - Carregar colaboradores
      UI.loader("show", "Carregando colaboradores...");
      API.get("/colaboradores")
      .then((json) => {
        let colaboradores = json.data.filter((col) => { return col.nsu; });

        // 3 - Carregar Eventos
        UI.loader("show", "Carregando eventos...");
        API.get("/eventos")
        .then((json) => {
          let eventos = json.data;

          this.setState({ pesquisa: "", lancamentos: lancamentos, colaboradores: colaboradores, eventos: eventos })
          UI.loader("hide");

        })
        .catch((json) => { // Eventos
          UI.alert(false, json.message);
          UI.loader("hide");
        })
      })
      .catch((json) => { // Colaboradores
        UI.alert(false, json.message);
        UI.loader("hide");
      })
    })
    .catch((json) => { // Lançamentos
      UI.alert(false, json.message);
      UI.loader("hide");
    });
    //UI.stackModals();
  }

  novoLancamento() {
    if (this.state.colaboradores.length === 0) {
      UI.alert(false, "Nenhum colaborador disponível para lançamento.", "info");
      return;
    }
    if (this.state.eventos.length === 0) {
      UI.alert(false, "Nenhum evento disponível para lançamento.", "info");
      return;
    }
    UI.toggleModal("#pesquisa-colaborador-modal");
  }

  salvarLancamento(lanc) {
    this.state.lancamentos.push(lanc);
    this.forceUpdate();
  }

  removerLancamento(lanc) { 
    UI.confirm("Atenção", "Tem certeza que deseja excluir o lançamento?")
    .then((value) => {
      if (value) {
        UI.loader("show", "Removendo lancamento...");
        API.delete("/lancamentos/" + lanc.id)
          .then((json) => {
            let lista = this.state.lancamentos.filter((obj, i) => { return obj.id !== lanc.id; });
            this.setState({ lancamentos: lista });
            UI.loader("hide");
            UI.alert(json.success, json.message);
          })
          .catch((json) => {
            UI.loader("hide");
            UI.alert(false, json.message);
          })
      }
    })
  }

  componentWillUpdate() {
    UI.destroyDataTable(this.tableId);
  }

  componentDidUpdate() {
    UI.dataTable(this.tableId);
  }

  render() {
    if (!this.state) return false;

    let lancamentos = this.state.lancamentos.filter((lanc) => {
      let matchCount = 0;
      let palavras = this.state.pesquisa.split(" ");

      for (var i = 0; i < palavras.length; i++) { 
        let matches = false;

        let padMes = lanc.mes < 10 ? "0" : "";
        let periodo = padMes + lanc.mes + "/" + lanc.ano;
        
        matches = matches || periodo.includes(palavras[i]);
        matches = matches || lanc.colaborador.nome.includes(palavras[i]);
        matches = matches || lanc.evento.descricao.includes(palavras[i]);

        if (matches) {
          matchCount++;
        }
      }
      return matchCount === palavras.length;
    });

    this.tableId = StringUtil.uuid(5);

    return (
      <div className="row">
        <div className="col-md-12">          
          <div className="input-group">
            <input
              autoComplete="off"
              autoFocus={true}
              spellCheck={false}
              placeholder="Pesquisar"
              name="pesquisa"
              className="form-control search-control"
              value={this.state.pesquisa}
              onChange={this.handleChange}
            />
            <div className="input-group-append search">
              <span className="input-group-text">
                <i className="fa fa-search fa-lg" />
              </span>
            </div>
          </div>
        </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div className="form-group row">
                <div className="col-md-6">
                  <h4 className="card-title">LANÇAMENTOS DE EVENTOS</h4>
                </div>
                <div className="col-md-6 text-right">
                  <button
                    onClick={() => { this.novoLancamento() }}
                    className="btn btn-add btn-primary"
                    type="button"
                  >
                    <i className="fa fa-plus"></i>&nbsp;
                    Novo Lançamento
                    </button>
                </div>
              </div>

              {lancamentos.length > 0 &&
                <table key={StringUtil.uuid(5)} className="table"  id={this.tableId}>
                  <thead>
                    <tr>
                      <th style={{ width: "1%", whiteSpace: "nowrap" }}>Período</th>
                      <th>Colaborador</th>
                      <th>Evento</th>
                      <th>Qtd.</th>
                      <th>Valor (R$)</th>
                      <th style={{ width: "1%" }}></th>
                      <th style={{ width: "1%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {lancamentos.map((lanc) => {
                      let padMes = lanc.mes < 10 ? "0" : "";

                      return (
                        <tr key={lanc.id}>
                          <td>{padMes + lanc.mes + "/" + lanc.ano}</td>
                          <td>{lanc.colaborador.nsu + " - " + lanc.colaborador.nome}</td>
                          <td>{lanc.evento.nsu + " - " + lanc.evento.descricao}</td>
                          <td>{lanc.quantidade}</td>
                          <td>{lanc.valor.toFixed(2)}</td>
                          <td style={{ fontWeight: "700", color: "#aaa", whiteSpace: "nowrap" }}>{lanc.dataHoraImportacao ? "" : "PENDENTE"}</td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            <button type="button" className="btn btn-default btn-option icon-btn" id="dropdown1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="fa fa-gear"></i>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdown1">
                              <span  onClick={() => this.removerLancamento(lanc)} className="dropdown-item"><i className="fa fa-times"></i>&nbsp;Excluir</span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>}

            </div>
          </div>
        </div>

        <LancamentoModal eventos={this.state.eventos} lista={this.state.colaboradores} onSalvar={this.salvarLancamento} />
      </div>
    );
  }
}

export default withRouter(LancamentoLista);
