import React, { Component } from "react";
import InputMask from "react-input-mask";

import API from "../../../services/api";
import UI from "../../../services/interface";
import StringUtil from "../../../services/util/string";
import DataUtil from "../../../services/util/datahora";

class ColaboradorModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getInitialState() {
    return {
      id: 0,
      nome: "",
      dataNascimento: "",
      telefoneResidencial: "",
      telefoneCelular: "",
      email: "",
      sexo: "M",
      documentacao: {
        numeroCpf: "",
        numeroPis: ""
      },
      contrato: {
        dataAdmissao: DataUtil.hoje()
      }
    };
  }

  handleChange(event) {
    let names = event.target.name.split(".");
    if (names.length === 1) {
      this.setState({ [event.target.name]: event.target.value });
    }
    else {
      let obj = this.state[names[0]];
      obj[names[1]] = event.target.value;
      this.setState({ [names[0]]: obj });
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    let colab = {...this.state};
    colab.documentacao.numeroCpf = StringUtil.unmask(colab.documentacao.numeroCpf);    
    colab.telefoneResidencial = StringUtil.unmask(colab.telefoneResidencial);
    colab.telefoneCelular = StringUtil.unmask(colab.telefoneCelular);

    if (!StringUtil.validarCpf(colab.documentacao.numeroCpf)) {
      UI.alert(false, "Verifique o número de CPF informado.", "warning").then((json) => document.getElementsByName("documentacao.numeroCpf")[0].focus());
      return;
    }
    if (!DataUtil.validarData(colab.dataNascimento)) {
      UI.alert(false, "Verifique a data de nascimento informada.", "warning").then((json) => document.getElementsByName("dataNascimento")[0].focus());
      return;
    }
    if (!DataUtil.validarData(colab.contrato.dataAdmissao)) {
      UI.alert(false, "Verifique a data de admissão informada.", "warning").then((json) => document.getElementsByName("contrato.dataAdmissao")[0].focus());
      return;
    }
    if (colab.nome.length < 3 || !colab.nome.includes(" ")) {
      UI.alert(false, "Informe o nome completo do colaborador.", "warning").then((json) => document.getElementsByName("nome")[0].focus());
      return;
    }

    UI.loader("show", "Cadastrando colaborador...");
    API.post("/colaboradores", colab)
      .then((json) => {
        this.setState(this.getInitialState());
        this.props.onNovoColaborador(json.data);
        UI.toggleModal("#novo-colaborador-modal");
        UI.alert(json.success, json.message);
        UI.loader("hide");
      })
      .catch((json) => {
        UI.alert(false, json.message);
        UI.loader("hide");
      })
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div
          className="modal fade"
          id="novo-colaborador-modal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <i className="fa fa-plus"></i>&nbsp;
                  Novo Colaborador
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group row">
                  <div className="col-md-3">
                    <label>CPF</label>
                    <InputMask
                      placeholder="Número do CPF"
                      name="documentacao.numeroCpf"
                      className="form-control"
                      mask="999.999.999-99"
                      onChange={this.handleChange}
                      value={this.state.documentacao.numeroCpf}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>PIS</label>
                    <input
                      placeholder="Número do PIS"
                      name="documentacao.numeroPis"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.documentacao.numeroPis}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Data Nascimento</label>
                    <InputMask
                      placeholder="Data de nascimento"
                      name="dataNascimento"
                      className="form-control"
                      mask="99/99/9999"
                      onChange={this.handleChange}
                      value={this.state.dataNascimento}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Data Admissão</label>
                    <InputMask
                      placeholder="Data de admissão"
                      name="contrato.dataAdmissao"
                      className="form-control"
                      mask="99/99/9999"
                      onChange={this.handleChange}
                      value={this.state.contrato.dataAdmissao}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-9">
                    <label>Nome Completo</label>
                    <input
                      autoComplete="off"
                      placeholder="Nome completo"
                      name="nome"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.nome}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Sexo</label>
                    <select
                      name="sexo"
                      className="form-control"
                      value={this.state.sexo}
                      onChange={this.handleChange}
                    >
                      <option value="M">MASCULINO</option>
                      <option value="F">FEMININO</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-3">
                    <label>Telefone Residencial</label>
                    <InputMask
                      placeholder="(99) 9999-9999"
                      name="telefoneResidencial"
                      className="form-control"
                      mask="(99) 9999-9999"
                      onChange={this.handleChange}
                      value={this.state.telefoneResidencial}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Telefone Celular</label>
                    <InputMask
                      placeholder="(99) 99999-9999"
                      name="telefoneCelular"
                      className="form-control"
                      mask="(99) 99999-9999"
                      onChange={this.handleChange}
                      value={this.state.telefoneCelular}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>E-mail</label>
                    <input
                      placeholder="E-mail"
                      name="email"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.email}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button type="submit" className="btn btn-primary">
                  Pronto
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default ColaboradorModal;
