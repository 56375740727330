import API from "./api";

/* global $, swal */

const UI = {
  toggleMenu: () => {
    $(".mobile-menu li").removeClass("active");
    let menuId = "0";

    let pathname = window.location.pathname;
    switch(pathname) {
      case "/folha/colaboradores":
        menuId = "1";
        break;
      case "/folha/lancamentos":
        menuId = "2";
        break;
      case "/folha/ferias":
        menuId = "3";
        break;
      case "/folha/rescisao":
        menuId = "4";
        break;
      case "/documentos":
        menuId = "5";
        break;
      default:
        menuId = "0";
        break;
    }

    $("#mobile-menu-" + menuId).addClass("active");
    $("#mobile-backdrop").toggle();
    $("#mobile-menu").toggle();
  },

  abrirDocumento: (doc) => {
    var prefixo = "";
    var sufixo = "";
    var extensao = doc.arquivoNome.lastIndexOf("."); 
    extensao = doc.arquivoNome.substring(extensao).toUpperCase();

    if (extensao === ".PDF" || extensao === ".DOC" || extensao === ".DOCX" || extensao === ".XLS" || extensao === ".XLSX") {
      prefixo = "https://docs.google.com/viewerng/viewer?url=";
      sufixo = "&embedded=true";
    }

    if (extensao === ".PDF" || extensao === ".DOC" || extensao === ".DOCX" || extensao === ".XLS" || extensao === ".XLSX") {
      UI.loader("show", "Abrindo documento...");
      document.getElementById("iframe-documento").src = "/loading";
      document.getElementById("iframe-documento").onload = () => {
        UI.loader("hide");
        document.getElementById("iframe-documento").onload = () => {};
        document.getElementById("iframe-documento").src = prefixo + API.baseUrl + doc.linkDownload + sufixo;
        document.getElementById("nome-documento").innerText = doc.arquivoNome;
        $("#documento-modal").modal("toggle");
      }
    }
    else {
      var link = document.createElement("a");
      link.setAttribute("href", API.baseUrl + doc.linkDownload);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
  
      link.click();
      document.body.removeChild(link);
    }
  },

  removeBackdrops: () => {
    $(".modal-backdrop").remove();
  },

  loader: (action, text) => {
    if (action === "show") {
      $("#loader").show();
      $("#loader-text").text(text ? text : "");
    } else {
      $("#loader").hide();
    }
  },

  toggleModal: id => {
    $(id).modal("toggle");
  },

  showModal: id => {
    $(id).modal('show');
  },

  alert: (success, message, icon, title) => {
    let object = {
      title: title,
      text: message,
      icon: !icon ? (success ? "success" : "error") : icon,
      button: {
        text: "Entendi",
        value: true,
        visible: true,
        className: "btn " + (success ? "btn-primary" : "btn-danger")
      }
    }
    return swal(object);
  },

  confirm: (title, message) => {
    let object = {
      title: title,
      text: message,
      icon: "info",
      showCancelButton: true,
      buttons: {
        cancel: {
          text: "Cancelar",
          value: null,
          visible: true,
          className: "btn btn-danger",
          closeModal: true,
        },
        confirm: {
          text: "Tenho",
          value: true,
          visible: true,
          className: "btn btn-primary",
          closeModal: true
        }
      }
    }
    return swal(object);
  },

  inputHelpers: () => {
    $(".form-check .form-check-label,.form-radio .form-check-label")
      .not(".todo-form-check .form-check-label")
      .append('<i class="input-helper"></i>');
  },

  dataTable: (id) => {
    id = "#" + id;
    if (!$.fn.dataTable.isDataTable(id)) {
      $(id).DataTable({
        "iDisplayLength": 8,
        "bFilter": false,
        "bLengthChange": false,
        "bDestroy": true,
        "language": {
          "emptyTable": "Nenhum registro encontrado",
          "infoEmpty": "Página 1 de 1",
          "paginate": {
            "previous": "<",
            "next": ">"
          },
          "info": "Página _PAGE_ de _PAGES_"
        }
      });
    }
  },

  destroyDataTable: (id) => {
    id = "#" + id;
    $(id).dataTable().fnDestroy();
  },

  stackModals() {
    $('.modal').on('hidden.bs.modal', function(event) {
      $(this).removeClass( 'fv-modal-stack' );
      $('body').data( 'fv_open_modals', $('body').data( 'fv_open_modals' ) - 1 );
    });

    $('.modal').on('shown.bs.modal', function (event) {
      // keep track of the number of open modals
      if (typeof($('body').data('fv_open_modals')) == 'undefined'){
        $('body').data( 'fv_open_modals', 0 );
      }

      // if the z-index of this modal has been set, ignore.
      if ( $(this).hasClass('fv-modal-stack')) {
        return;
      }

      $(this).addClass( 'fv-modal-stack' );
      $('body').data( 'fv_open_modals', $('body').data( 'fv_open_modals' ) + 1 );
      $(this).css('z-index', 1040 + (10 * $('body').data( 'fv_open_modals' )));
      $('.modal-backdrop').not('.fv-modal-stack' ).css( 'z-index', 1039 + (10 * $('body').data( 'fv_open_modals' )));
      $( '.modal-backdrop' ).not( 'fv-modal-stack' ).addClass( 'fv-modal-stack' ); 
    });
  }
};

export default UI;
