import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import UI from "./services/interface";
import API from "./services/api";
import Loader from "./components/loader/Loader";

import Login from "./components/acesso/Login";
import Main from "./components/main/Main";
import Loading from "./components/main/common/loading/Loading";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { pronto: false, conectado: false };
    this.getPerfil = this.getPerfil.bind(this);
  }

  componentDidMount() {
    this.getPerfil();
  }

  componentDidUpdate() {
    UI.removeBackdrops();
  }

  componentWillReceiveProps(props) {
    switch (props.location.state) {
      case "LOGIN":
        this.getPerfil();
        break;
      case "LOGOUT":
        this.setState({ conectado: false });
        break;
      default:
        if (props.location.state && props.location.state.action === "TROCAR_EMPRESA") {
          API.trocarEmpresa(props.location.state.value);
          window.location.href = "/";
        }
        this.forceUpdate();
    }
  }

  getPerfil() {
    if (window.location.pathname === "/loading") {
      this.setState({ pronto: true, conectado: false });
      return;
    }

    UI.loader("show", "Autenticando...");
    if (localStorage.getItem("accessToken")) {
      API.get("/perfil")
        .then((json) => {
          API.usuario = json.data.usuario;
          API.empresa = json.data.empresa;
          API.empresas = json.data.empresas;

          if (API.empresas.length === 0) {
            UI.loader("hide");
            UI.alert(false, "Este usuário não tem permissão para acessar nenhuma empresa.");
            return;
          }

          if (!API.empresa) {
            localStorage.setItem("accessToken", API.empresas[0].accessToken);
          }

          this.setState({ pronto: true, conectado: true });
          UI.loader("hide");
        })
        .catch((json) => {
          UI.loader("hide");
          UI.alert(false, json.message);
          this.setState({ pronto: true, conectado: false });
        });
    }
    else {
      UI.loader("hide");
      this.setState({ pronto: true, conectado: false });
    }
  }
  
  render() {
    return (
      <React.Fragment>
        {this.state.pronto &&
          <Switch>
            <Route exact path="/loading" component={Loading} /> 
            {!this.state.conectado && <Route path="/" component={Login} />}
            <Route path="/" component={Main} />
          </Switch>}
        <Loader />
      </React.Fragment>
    );
  }
}

export default withRouter(App);
