import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import InputMask from "react-input-mask";

import UI from "../../../../services/interface";
import StringUtil from "../../../../services/util/string";
import DataUtil from "../../../../services/util/datahora";
import API from "../../../../services/api";

class Colaborador extends Component {
  constructor(props) {
    super(props);
    this.state = false;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    UI.loader("Carregando informações do colaborador...")
    API.get("/colaboradores/" + this.props.match.params.id)
    .then((json) => {
      this.setState(json.data);
      UI.loader("hide");
    })
    .catch((json) => {
      UI.alert(false, json.message);
      UI.loader("hide");
    });
  }

  handleChange(event) {
    let names = event.target.name.split(".");
    if (names.length === 1) {
      this.setState({ [event.target.name]: event.target.value });
    }
    else {
      let obj = this.state[names[0]];
      obj[names[1]] = event.target.value;
      this.setState({ [names[0]]: obj });
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    let colab = {...this.state};
    colab.documentacao.numeroCpf = StringUtil.unmask(colab.documentacao.numeroCpf);
    colab.telefoneResidencial = StringUtil.unmask(colab.telefoneResidencial);
    colab.telefoneCelular = StringUtil.unmask(colab.telefoneCelular);

    if (!StringUtil.validarCpf(colab.documentacao.numeroCpf)) {
      UI.alert(false, "Verifique o número de CPF informado.", "warning").then((json) => document.getElementsByName("documentacao.numeroCpf")[0].focus());
      return;
    }
    if (!DataUtil.validarData(colab.dataNascimento)) {
      UI.alert(false, "Verifique a data de nascimento informada.", "warning").then((json) => document.getElementsByName("dataNascimento")[0].focus());
      return;
    }
    if (!DataUtil.validarData(colab.contrato.dataAdmissao)) {
      UI.alert(false, "Verifique a data de admissão informada.", "warning").then((json) => document.getElementsByName("contrato.dataAdmissao")[0].focus());
      return;
    }
    if (colab.nome.length < 3 || !colab.nome.includes(" ")) {
      UI.alert(false, "Informe o nome completo do colaborador.", "warning").then((json) => document.getElementsByName("nome")[0].focus());
      return;
    }

    UI.loader("show", "Cadastrando colaboradores...");
    API.put("/colaboradores/" + colab.id, colab)
      .then((json) => {
        UI.alert(json.success, json.message);
        UI.loader("hide");
      })
      .catch((json) => {
        UI.alert(false, json.message);
        UI.loader("hide");
      })
  }

  render() {
    if (!this.state) return false;
    return (
      <form
        className="form-sample"
        autoComplete="off"
        spellCheck="false"
        onSubmit={this.handleSubmit}
      >
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-md-4">
                    <h4 className="card-title">CADASTRO DO COLABORADOR</h4>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-3">
                    <label>CPF</label>
                    <InputMask
                      placeholder="Número do CPF"
                      name="documentacao.numeroCpf"
                      className="form-control"
                      mask="999.999.999-99"
                      onChange={this.handleChange}
                      value={this.state.documentacao.numeroCpf}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>PIS</label>
                    <input
                      placeholder="Número do PIS"
                      name="documentacao.numeroPis"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.documentacao.numeroPis}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Data Nascimento</label>
                    <InputMask
                      placeholder="Data de nascimento"
                      name="dataNascimento"
                      className="form-control"
                      mask="99/99/9999"
                      onChange={this.handleChange}
                      value={this.state.dataNascimento}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Data Admissão</label>
                    <InputMask
                      placeholder="Data de admissão"
                      name="contrato.dataAdmissao"
                      className="form-control"
                      mask="99/99/9999"
                      onChange={this.handleChange}
                      value={this.state.contrato.dataAdmissao}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-9">
                    <label>Nome Completo</label>
                    <input
                      autoComplete="off"
                      placeholder="Nome completo"
                      name="nome"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.nome}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Sexo</label>
                    <select
                      name="sexo"
                      className="form-control"
                      value={this.state.sexo}
                      onChange={this.handleChange}
                    >
                      <option value="M">MASCULINO</option>
                      <option value="F">FEMININO</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-3">
                    <label>Telefone Residencial</label>
                    <InputMask
                      placeholder="(99) 9999-9999"
                      name="telefoneResidencial"
                      className="form-control"
                      mask="(99) 9999-9999"
                      onChange={this.handleChange}
                      value={this.state.telefoneResidencial}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Telefone Celular</label>
                    <InputMask
                      placeholder="(99) 99999-9999"
                      name="telefoneCelular"
                      className="form-control"
                      mask="(99) 99999-9999"
                      onChange={this.handleChange}
                      value={this.state.telefoneCelular}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>E-mail</label>
                    <input
                      placeholder="E-mail"
                      name="email"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.email}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-right">
                    <button type="submit" className="btn btn-primary mb-2">
                      Concluido
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(Colaborador);
