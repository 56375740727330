import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import UI from "../../../services/interface";
import API from "../../../services/api";

import StringUtil from "../../../services/util/string";

import RescisaoModal from "./RescisaoModal";
import DocumentoDiretorioModal from "../documento/DocumentoDiretorioModal";

class RescisaoLista extends Component {
  constructor(props) {
    super(props);
    this.state = false;
    this.handleChange = this.handleChange.bind(this);
    this.criarSolicitacao = this.criarSolicitacao.bind(this);
    this.editarSolicitacao = this.editarSolicitacao.bind(this);
    this.addSolicitacao = this.addSolicitacao.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value.toUpperCase() })
  }

  componentDidMount() {
    UI.loader("show", "Buscando colaboradores...")
    API.get("/colaboradores")
    .then((json) => {
      let listaColaboradores = json.data.filter((col) => { return col.nsu; });

      // Buscar solicitações
      UI.loader("show", "Buscando solicitações...");
      API.get("/solicitacoes/rescisao")
      .then((json) => {
        let listaSolicitacoes = json.data;
    
        // Buscar causas de rescisão
        UI.loader("show", "Buscando causas...");
        API.get("/causa-rescisao.json")
        .then((json) => {
          this.setState({ pesquisa: "", colaboradores: listaColaboradores, solicitacoes: listaSolicitacoes, causas: json });
          UI.loader("hide");
        })
      })
      .catch((json) => {
        UI.alert(false, json.message);
        UI.loader("hide");
      })
    })
    .catch((json) => {
      UI.alert(false, json.message);
      UI.loader("hide");
    });
  }

  criarSolicitacao() {
    if (this.state.colaboradores.length === 0) {
      UI.alert(false, "Nenhum colaborador disponível.", "info");
      return;
    } 
    UI.toggleModal("#solicitacao-rescisao-modal");
    this.setState({ solicitacao: null });
  }

  editarSolicitacao(sol) {
    if (sol.dataHoraImportacao) {
      UI.alert(false, "Este lançamento já foi importado no Unique e não pode mais ser editado.", "info");
      return;
    }
    UI.toggleModal("#solicitacao-rescisao-modal");
    this.setState({ solicitacao: sol });
  }

  addSolicitacao(sol) {
    let index = this.state.solicitacoes.findIndex((obj) => { return obj.id === sol.id });
    if (index < 0) { this.state.solicitacoes.unshift(sol); index = 0; }
    else { let solicitacoes = this.state.solicitacoes; solicitacoes[index] = sol; }
    UI.toggleModal("#solicitacao-rescisao-modal");
    this.forceUpdate();

    let table = document.getElementById("table-rescisao");
    if (table) {
      let row = table.children[1].children[index];
      row.classList.add("updated-row");
    }
  }

  removerSolicitacao(sol) {
    UI.confirm("Atenção", "Tem certeza que deseja excluir a solicitação de rescisão?")
    .then((value) => {
      if (value) {
        UI.loader("show", "Removendo solicitacoes...");
        API.delete("/solicitacoes/rescisao/" + sol.id)
          .then((json) => {
            let lista = this.state.solicitacoes.filter((obj, i) => { return obj.id !== sol.id; });
            this.setState({ solicitacoes: lista });
            UI.loader("hide");
            UI.alert(json.success, json.message);
          })
          .catch((json) => {
            UI.loader("hide");
            UI.alert(false, json.message);
          })
      }
    })
  }

  componentWillUpdate() {
    UI.destroyDataTable(this.tableId);
  }

  componentDidUpdate() {
    UI.dataTable(this.tableId);
  }

  abrirDocumentos(sol) {
    this.setState({ diretorio: "\\_RESCISAO\\" + sol.id, titulo: sol.colaborador.nome }, () => UI.showModal("#documento-diretorio-modal"));
  }

  render() {
    if (!this.state) return false;

    let solicitacoes = this.state.solicitacoes.filter((sol) => {
      let matches = false;
      matches = matches || sol.dataAfastamento.includes(this.state.pesquisa);
      matches = matches || sol.colaborador.nome.includes(this.state.pesquisa);
      return matches;
    });

    this.tableId = StringUtil.uuid(5);

    return (
      <div className="row">
        <div className="col-md-12">          
          <div className="input-group">
            <input
              autoComplete="off"
              autoFocus={true}
              spellCheck={false}
              placeholder="Pesquisar"
              name="pesquisa"
              className="form-control search-control"
              value={this.state.pesquisa}
              onChange={this.handleChange}
            />
            <div className="input-group-append search">
              <span className="input-group-text">
                <i className="fa fa-search fa-lg" />
              </span>
            </div>
          </div>
        </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <div className="form-group row">
                <div className="col-md-6">
                  <h4 className="card-title">SOLICITAÇÕES DE RESCISÃO</h4>
                </div>
                <div className="col-md-6 text-right">
                  <button
                    onClick={() => { this.criarSolicitacao(); }}
                    className="btn btn-add btn-primary"
                    type="button"
                  >
                    <i className="fa fa-plus"></i>&nbsp;
                    Nova Solicitação
                    </button>
                </div>
              </div>

              {solicitacoes.length > 0 &&
                <table key={StringUtil.uuid(5)} className="table"  id={this.tableId}>
                  <thead>
                    <tr>
                      <th style={{ width: "1%", whiteSpace: "nowrap" }}>Data do Afastamento</th>
                      <th style={{ whiteSpace: "nowrap" }}>Colaborador</th>
                      <th style={{ width: "1%" }}></th>
                      <th style={{ width: "1%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {solicitacoes.map((sol) => {
                      return (
                        <tr key={sol.id}>
                          <td style={{ whiteSpace: "nowrap" }}>{sol.dataAfastamento}</td>
                          <td style={{ whiteSpace: "nowrap" }}>{sol.colaborador.nsu + " - " + sol.colaborador.nome}</td>
                          <td style={{ fontWeight: "700", color: "#aaa", whiteSpace: "nowrap" }}>{sol.dataHoraImportacao ? "" : "PENDENTE"}</td>
                          <td style={{ whiteSpace: "nowrap" }}>
                            <button type="button" className="btn btn-default btn-option icon-btn" id="dropdown1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="fa fa-gear"></i>
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdown1">
                              <span onClick={() => this.editarSolicitacao(sol)} className="dropdown-item"><i className="fa fa-edit"></i>&nbsp;Editar</span>
                              <span onClick={() => this.abrirDocumentos(sol)} className="dropdown-item"><i className="fa fa-paperclip"></i>&nbsp;Documentos</span>
                              <span onClick={() => this.removerSolicitacao(sol)} className="dropdown-item"><i className="fa fa-times"></i>&nbsp;Excluir</span>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>}
            </div>
          </div>
        </div>

        <RescisaoModal colaboradores={this.state.colaboradores} onAddSolicitacao={this.addSolicitacao} solicitacao={this.state.solicitacao} causas={this.state.causas} />
        <DocumentoDiretorioModal diretorio={this.state.diretorio} titulo={this.state.titulo} />
      </div>
    );
  }
}

export default withRouter(RescisaoLista);