import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import "./common/css/main.css";
import "./common/css/datatable.css";

import Header from "./common/Header";
import Footer from "./common/Footer";

import Backdrop from "./common/mobile/Backdrop";
import Menu from "./common/mobile/Menu";

import Inicio from "./inicio/Inicio";
import Colaborador from "./colaborador/cadastro/Colaborador";
import ColaboradorLista from "./colaborador/ColaboradorLista";
import LancamentoLista from "./folha/LancamentoLista";
import Documento from "./documento/Documento";
import FeriasLista from "./ferias/FeriasLista";
import RescisaoLista from "./rescisao/RescisaoLista";

import NotFound from "./error/NotFound";

class Main extends Component {
  render() {
    return (
      <div className="container-scroller">
        <Header usuario={this.props.usuario} />
        <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
              <Switch>
                <Route exact path="/" component={Inicio} />
                <Route exact path="/folha/colaboradores/:id" component={Colaborador} />
                <Route exact path="/folha/colaboradores" component={ColaboradorLista} />
                <Route exact path="/folha/ferias" component={FeriasLista} />
                <Route exact path="/folha/rescisao" component={RescisaoLista} />
                <Route exact path="/folha/lancamentos" component={LancamentoLista} />
                <Route exact path="/documentos" component={Documento} />
                <Route path="/" component={NotFound} />
              </Switch>
            </div>
            <Footer />
          </div>
        </div>
        <Backdrop />
        <Menu />
      </div>
    );
  }
}

export default Main;
