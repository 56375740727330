import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import InputMask from "react-input-mask";

import DataUtil from "../../../services/util/datahora";
import UI from "../../../services/interface";
import API from "../../../services/api";

class RescisaoModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getInitialState = this.getInitialState.bind(this);
  }

  getInitialState() {
    return {
        id: 0,
        colaboradorId: this.props.colaboradores.length > 0 ? this.props.colaboradores[0].id :  0,
        causaId: this.props.causas[0].id,
        dataAfastamento: "",
        dataAviso: "",
        avisoId: "1"
    };
  }

  componentWillReceiveProps(props) {
    if (props.solicitacao) {
      let solicitacao = props.solicitacao;
      this.setState({
        id: solicitacao.id,
        colaboradorId: solicitacao.colaborador.id,
        causaId: solicitacao.causaId,
        dataAfastamento: solicitacao.dataAfastamento,
        dataAviso: solicitacao.dataAviso,
        avisoId: solicitacao.avisoId
      });
    }
    else {
      this.setState(this.getInitialState());
    }
  }

  handleChange(event) {
    let names = event.target.name.split(".");
    if (names.length === 1) {
      this.setState({ [event.target.name]: event.target.value });
    }
    else {
      let obj = this.state[names[0]];
      obj[names[1]] = event.target.value;
      this.setState({ [names[0]]: obj });
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!DataUtil.validarData(this.state.dataAfastamento)) {
      UI.alert(false, "Verifique a data do afastamento.", "info");
      return;
    }
    if (parseInt(this.state.causaId) === 0) {
      UI.alert(false, "É obrigatório informar a causa da demissão.", "info");
    }

    UI.loader("show", "Salvando solicitação...");
    API.post("/solicitacoes/rescisao", this.state)
    .then((json) => {
      this.props.onAddSolicitacao(json.data);
      UI.alert(json.success, json.message);
      UI.loader("hide");
    })
    .catch((json) => {
      UI.alert(false, json.message);
      UI.loader("hide");
    });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div
          className="modal fade"
          id="solicitacao-rescisao-modal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  <i className="fa fa-plus"></i>&nbsp;
                  Nova Solicitação
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group row">
                  <div className="col-md-12">
                    <label>Colaborador</label>
                    <select
                      name="colaboradorId"
                      className="form-control"
                      value={this.state.colaboradorId}
                      onChange={this.handleChange}
                    >
                      {this.props.colaboradores.map((col) => {
                        return (
                          <option key={col.id} value={col.id}>{col.nsu + " - " + col.nome}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-md-12">
                    <label>Causa</label>
                    <select
                      name="causaId"
                      className="form-control"
                      value={this.state.causaId}
                      onChange={this.handleChange}
                    >
                      {this.props.causas.map((cau) => {
                        return (
                          <option key={cau.id} value={cau.id}>{cau.id + " - " + cau.descricao}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group row">  
                  <div className="col-md-6">
                    <label>Aviso Prévio</label>
                    <select
                      name="avisoId"
                      className="form-control"
                      value={this.state.avisoId}
                      onChange={this.handleChange}
                    >
                      <option value="1">PAGAR AVISO</option>
                      <option value="2">DESCONTAR AVISO</option>
                      <option value="3">NÃO CALCULAR</option>
                      <option value="4">ACORDO</option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label>Data do Afastamento</label>
                    <InputMask
                      placeholder="99/99/9999"
                      name="dataAfastamento"
                      className="form-control"
                      mask="99/99/9999"
                      value={this.state.dataAfastamento}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button type="submit" className="btn btn-primary">
                  Pronto
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(RescisaoModal);
